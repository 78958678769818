import sbjs from 'sourcebuster';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

sbjs.init();

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()

;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // write the visitorId to the cookie
    document.cookie = `fpjs_visitor_id=${result.visitorId}; path=/; max-age=31536000; SameSite=Lax; Secure`;
})();
